import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import {
  Container,
  Row,
  CardGroup,
  Col,
  Media
} from "reactstrap";
import { Link, graphql } from "gatsby";
import { FormattedMessage } from 'react-intl';

import locales from '../constants/locales';
import Layout from '../components/Layout';
import LocalizedLink from '../components/LocalizedLink';
import CardBlog from '../components/UIComponents/Cards/CardBlog';
import TriDymeIcon from '../media/TriDymeIconV1.svg';
import HeadMeta from '../components/HeadMeta';
import Title from '../components/UIComponents/Title';

const {
  REACT_COMPANY_NAME
} = process.env;

const metaData = {
  en: {
    title: `TriDyme | The World of Construction`,
    pageTitle: "The World of Construction",
    description: "Your hub for everything you need to know about the world of Construction and Design"
  },
  fr: {
    title: `TriDyme | Actualités du BTP`,
    pageTitle: "Actualités du BTP",
    description: "Actualités et nouveautés dans le bâtiment et la construction. Chaque semaine, retrouvez nos informations BTP."
  }
};

const Blog = ({ pageContext: { locale }, data }) => {
  return (
    <Layout locale={locale}>
      <HeadMeta
        locale={locale}
        metaData={metaData}
      />
      <Container className="blogContainer">
        <Row>
          <Title
            icon={TriDymeIcon}
            title={metaData[locale].pageTitle}
            description={metaData[locale].description}
          // itemNumber={data.allMarkdownRemark.totalCount}
          />
        </Row>
        {/* <Row>
          <Col sm={{ size: 'auto' }}>
            <h2 className='subtitle'>
              {metaData[locale].description}
            </h2>
          </Col>
        </Row> */}
        {/* <Row>
          <Col sm={{ size: 'auto' }}>
            <h3 className="subtitle">{data.allMarkdownRemark.totalCount} Posts</h3>
          </Col>
        </Row> */}
        <hr />
        <CardGroup className="cardGroup">
          {data.allMarkdownRemark.edges.map(({ node }) => {
            return (
              <CardBlog
                key={node.id}
                colnum={4}
                imgsrc={node.frontmatter.featuredImage.publicURL}
                title={node.frontmatter.title}
                subtitle={node.frontmatter.date}
                language={node.frontmatter.language}
                status={node.frontmatter.status}
                description={node.excerpt}
                link={locales[locale].default
                  ? '/' + node.fields.slug
                  : `/${locales[locale].path}` + node.fields.slug}
                textButtonId="read"
              />
            );
          })}
        </CardGroup>
      </Container>
    </Layout>
  )
}

Blog.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default Blog;

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: {regex : "\/blog/"} },
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD-MM-YYYY")
            language
            featuredImage {
              publicURL
            }
            description
            status
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`