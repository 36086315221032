import React from "react";
import PropTypes from 'prop-types';
import {
  Row,
  Media
} from 'reactstrap';

const Title = ({
  icon,
  title,
  description,
  itemNumber
}) => (
  <Media className="titleComponent">
    <Media left href="#">
      <Media className="titleimg" object src={icon} alt="TriAzur Blog" />
    </Media>
    <Media body className="titletext">
      <Row>
        <h1 className='title'>
          {title}
        </h1>
      </Row>
      <Row>
        <h2 className='subtitle'>
          {description}
        </h2>
      </Row>
      <Row>
        <h6>
          {itemNumber && 
            `${itemNumber} Posts`
          } 
        </h6>
      </Row>
    </Media>
  </Media>
);

export default Title;